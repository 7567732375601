.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
}

.whatsapp-button svg {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.whatsapp-button svg:hover {
    transform: scale(1.1);
}